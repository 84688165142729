<template>
  <!-- Show custom list in desktop only -->
  <div class="hidden canhover:block" v-bind="$attrs" :class="{ disabled }">
    <HeadlessListbox
      as="div"
      v-model="selected"
      :disabled="disabled"
      class="relative"
    >
      <HeadlessListboxLabel
        v-if="label"
        class="absolute z-[1] -top-2 left-2 px-1 bg-neutral-100 dark:bg-neutral-900 text-xs input-label-text rounded"
        :class="{ error: !!error }"
        >{{ label }}
      </HeadlessListboxLabel>
      <div class="relative">
        <HeadlessListboxButton :class="{ error: !!error }" class="w-full">
          <slot name="button">
            <div
              class="relative grid items-center h-[3rem] pl-3 pr-10 min-h-[3rem] w-full cursor-pointer rounded-md outline-none input-border input-text bg-neutral-100 dark:bg-neutral-900 text-left text-sm sm:text-base"
            >
              <span class="block truncate">{{ buttonText }}</span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <IconChevronDown
                  class="h-5 w-5 text-neutral-500"
                  aria-hidden="true"
                />
              </span>
            </div>
          </slot>
        </HeadlessListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <HeadlessListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-600 py-1 shadow-lg focus:outline-none text-sm sm:text-base"
          >
            <HeadlessListboxOption
              as="template"
              v-for="(option, index) in options"
              :key="String(option.value)"
              :value="option"
              :disabled="option.disabled"
              v-slot="{ active, selected }"
            >
              <li>
                <slot
                  name="option"
                  v-bind="{
                    active,
                    option,
                    selected,
                    index,
                    isLast: index === options.length - 1,
                    isFirst: index === 0,
                  }"
                />
              </li>
            </HeadlessListboxOption>
          </HeadlessListboxOptions>
        </transition>
      </div>
    </HeadlessListbox>
  </div>
  <!-- Show OS list in mobiles only -->
  <div class="canhover:hidden relative" v-bind="$attrs" :class="{ disabled }">
    <label
      v-if="label"
      class="absolute z-[1] -top-2 left-2 px-1 bg-neutral-100 dark:bg-neutral-900 text-xs input-label-text"
      :class="{ error: !!error }"
      >{{ label }}</label
    >
    <select
      @change="
        (e) =>
          (selected = options.find(
            (option) => option.value === (e.target as HTMLSelectElement).value,
          )!)
      "
      class="relative min-h-[3rem] w-full cursor-pointer rounded-md py-2 pl-3 pr-10 outline-none input-border input-text bg-neutral-100 dark:bg-neutral-900 text-left text-sm sm:text-base"
      :class="{ error: !!error }"
      :disabled="disabled"
      :value="modelValue"
    >
      <option
        v-for="(option, index) in options"
        :key="String(option.value)"
        :value="option.value"
        :class="{
          'text-black dark:text-white': !!error,
        }"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="T extends string | number | undefined | null, V extends object"
>
import { IconChevronDown } from "@tabler/icons-vue";

export interface Props<
  T extends string | number | undefined | null,
  V extends object,
> {
  modelValue: T;
  options: { text: string; value: T; disabled?: boolean; props?: V }[];
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

interface Events {
  (e: "update:modelValue", v: T): void;
}

const props = defineProps<Props<T, V>>();
const emit = defineEmits<Events>();

const selected = computed({
  get() {
    return (
      props.options.find((option) => option.value === props.modelValue) ?? {
        text: "",
        value: "" as T,
      }
    );
  },
  set(selection: { text: string; value: Props<T, V>["modelValue"] }) {
    emit("update:modelValue", selection.value);
  },
});

const buttonText = computed(() =>
  selected.value.text ? selected.value.text : props.placeholder,
);
</script>
