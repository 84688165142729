<template>
  <NamiSelectSingle
    :modelValue="model"
    @update:modelValue="(v) => emit('update:modelValue', v)"
    :options="options"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
  >
    <template #option="{ active, option, selected, index, isLast, isFirst }">
      <slot
        name="option"
        v-bind="{ active, option, selected, index, isLast, isFirst }"
      >
        <SelectSingleListOption
          :active="active"
          :option="option"
          :selected="selected"
          :disabled="option.disabled"
        />
      </slot>
    </template>
    <template #button v-if="$slots.button">
      <slot name="button" />
    </template>
  </NamiSelectSingle>
</template>

<script
  setup
  lang="ts"
  generic="T extends string | number | undefined | null, V extends object"
>
import type { Props } from "~/components/nami/select/NamiSelectSingle.vue";

const props = defineProps<Props<T, V>>();
const model = computed(() => props.modelValue);
const emit = defineEmits<{
  (e: "update:modelValue", v: Props<T, V>["modelValue"]): void;
}>();
</script>
